<template>
  <div id="history" class="bg-gray-relative-100">
    <div class="pb-3">
      <SubHeader title="이번달 취소내역"></SubHeader>
    </div>
    <div class="px-3 mb-2">
      <div class="flex-between-center px-1 mt-4">
        <span>
          <small class="text-gray-relative-600 me-2">합계</small
          >{{ total_price.toLocaleString() }}
          <small class="text-gray-relative-600">원</small>
          <small class="text-gray-relative-600 fs-px-11 ms-2 me-2">/</small
          >{{ total_count.toLocaleString() }}
          <small class="text-gray-relative-600">건</small>
        </span>
        <span>
          <img
            src="@/assets/img/icon/excel.svg"
            alt="엑셀로 내보내기"
            width="27"
            @click="ExcelDownload()"
          />
        </span>
      </div>
    </div>
    <div class="mx-3 bg-body rounded-4 shadow-400">
      <div class="my-2 pb-2">
        <ul class="mx-3 last-border-0" v-if="list && list.length > 0">
          <li
            class="px-2 py-3 flex-between-center border-bottom border-gray-relative-200"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="flex-between-center">
              <div class="d-flex flex-column fs-px-13">
                <small class="text-gray-relative-500">{{ item.date }}</small>
                <p class="lh-1 fw-bold">{{ item.payment_name }}</p>
              </div>
            </div>
            <div class="flex-between-center">
              <div class="d-flex flex-column border-end pe-3">
                <small class="text-end text-danger"
                  >-{{ item.price.toLocaleString() }}원</small
                >
                <span class="fs-px-12 text-end text-gray-relative-500"
                  ><span>{{ item.user_name }} 고객</span></span
                >
              </div>
              <span
                class="badge w-px-30 h-px-30 rounded-3 fw-normal fs-px-12 px-2 ms-3 d-flex flex-column align-items-center justify-content-center shadow-100 border"
                @click="$router.push(`/payment/receipt/${item.code}`)"
              >
                <i class="fal fa-receipt text-muted"></i>
              </span>
            </div>
          </li>
        </ul>
        <div class="p-3 py-6 text-center text-gray-relative-500" v-else>
          <i
            class="fal fa-shopping-cart fa-3x text-gray-relative-400 d-block my-3"
          ></i>
          <p class="my-2 fs-px-13">
            거래 내역이 없어요.<br />상점에서 상품권을 구입하고 선물할 수
            있어요.
          </p>
        </div>
      </div>
    </div>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue';
import Loading from '@/components/common/Loading.vue';
const CryptoJS = require('crypto-js');
var moment = require('moment');
import * as XLSX from 'xlsx';

export default {
  components: {
    SubHeader,
    Loading,
  },
  data() {
    return {
      total_price: '',
      total_count: '',
      list: [],
      loading: false,
    };
  },
  computed: {},
  mounted() {
    this.GetHistoryMonthCancel();
  },
  methods: {
    ExcelDownload() {
      let now = moment().format('YYYY-MM-DD');
      let excel = [];
      for (const el of this.list) {
        excel.push({
          날짜: el.date,
          결제방법: el.payment_name,
          고객이름: el.user_name,
          금액: el.price,
        });
      }

      const dataWS = XLSX.utils.json_to_sheet(excel);
      const wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, dataWS, `${now}`);
      XLSX.writeFile(wb, `이번 달 취소 목록[${now}].xlsx`);
    },
    GetHistoryMonthCancel() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http
        .post('/shop/history/GetHistoryMonthCancel', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              const e_body = res.data.body;
              const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
              );
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res);
              this.list = body.list;
              this.total_price = body.info.price;
              this.total_count = body.info.count;
            } else if (res.data.code == '9999') {
              this.$store.dispatch('SETLOGOUT').then(() => {
                window.location.href = '/signin';
              });
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#history {
  min-height: calc(100vh - 70px) !important;
  padding-bottom: 20px;
}
.category {
  padding: 3px;
}
.buy_type {
  background: linear-gradient(to bottom, #5d9cec, #4a89dc);
}
.send_type {
  background: linear-gradient(to bottom, #3f51b5, #243e87);
}
.text-send {
  color: #3f51b5;
}
.check_type {
  background: linear-gradient(to bottom, #a0d468, #8cc152);
}
</style>
